'use strict';

angular.module('enervexSalesappApp').factory('Document', function ($resource) {
	return $resource('/api/accounts/:accountId/jobs/:jobId/documents/:updateableVersions/:quoteId/:id/:versions/:version', {
		id: '@_id'
	},
	{
		update: {
			method: 'PUT'
		},
		version: {
			method: 'GET',
			params: {
				accountId: '@accountId',
				jobId: '@jobId',
				id: '@documentId',
				versions: 'versions',
				version: '@version'
			}
		},
		versions: {
			method: 'GET',
			isArray: true,
			params: {
				accountId: '@accountId',
				jobId: '@jobId',
				id: '@documentId',
				versions: 'versions'
			}
		},
		hasUpdatableVersions: {
			method: 'GET',
			isArray: true,
			params: {
				accountId: '@accountId',
				jobId: '@jobId',
				quoteId: '@quoteId',
				version: '@version',
				updateableVersions:'updateable-versions'
			}
		},
		updateVersions: {
			method: 'POST',
			isArray: true,
			params: {
				accountId: '@accountId',
				jobId: '@jobId',
				quoteId: '@quoteId',
				version: '@version',
				updateableVersions:'updateable-versions'
			}
		},
	});
});
